import React, {useState} from 'react';
import {works} from "../../constants/data";
import "./Work.css";
import {BsPlusLg} from "react-icons/bs";
import {ImCancelCircle} from "react-icons/im";

const Work = () => {
    const [imageModal, setImageModal] = useState(false);
    const [imageSource, setImageSource] = useState("");

    const setImageOnModal = (src) => {
        setImageModal(true);
        setImageSource(src);
    }

  return (
    <div className='work section-p' id = "location">
        <div className={imageModal ? "image-box show-image-box" : "image-box"}>
            <div className='image-box-content'>
                <img className='img-location' src = {imageSource} alt = "" />
                <span className='image-box-close-btnn' onClick={() => setImageModal(false)}>
                    <ImCancelCircle size = {30} />
                </span>
            </div>
        </div>

        <div className='container'>
            <div className='work-content'>
                <div className='section-title'>
                    <h3 className='text-brown'>VỊ TRÍ ĐẤT GIÁ <span className='text-dark'>PHONG THỦY VƯỢNG KHÍ</span></h3>
                    <p className='text content'>Khải Hoàn Prime tọa lạc tại vị trí vô cùng đắc địa trên tuyến đường Lê Văn Lương. Đây là 1 trong 4 tuyến giao thông huyết mạch của Nhà Bè, ngay tâm điểm phía Nam Sài Gòn, liền kề hai đô thị lớn là Phú Mỹ Hưng và Zeitgeist, nơi hội tụ vô vàn giá trị sống tiện nghi, hiện đại chính là khởi đầu cho một cuộc sống thịnh vượng với trải nghiệm sống thăng hoa.
Hơn hết, dự án còn sở hữu yếu tố vàng “nhất cận thị, nhị cận giang, tam cận lộ” mang đến tài lộc, thịnh vượng cho gia chủ:
Nhất cận thị: Tọa lạc tại tâm điểm Nhà Bè Khu vực được định hướng phát triển thành khu đô thị thông minh, sinh thái và vệ tinh phát triển phía Nam TP.HCM với tiềm năng phát triển kinh tế vượt bậc. Nhị cận giang: Sở hữu địa thế “trường lưu thủy” trải dọc sông Cầu Đạo uốn khúc - vị trí đắc địa trong phong thủy được xem là khởi nguồn của tài lộc.</p>
                </div>

                <div className='work-list grid'>
                    {
                        works.map((work, index) => {
                            return (
                                <div className='work-item text-center' key = {index} onClick = {() => setImageOnModal(work.image)}>
                                    <img src = {work.image} alt = "" />
                                    <span className='work-item-icon'>
                                        <BsPlusLg size = {38} className = "text-brown" />
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Work