import { Box, Button, Modal, Typography } from "@mui/material"
import axios from "axios";
import { useState } from "react"
import './popup.css'

const Popup = ({ showPopup, closePopup }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: ""
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.id]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name && !formData.email && !formData.phoneNumber) {
            setModalMessage('Vui lòng điền tất cả các thông tin.');
            setModalIsOpen(true);
            return;
        }

        try {
            await axios.post("https://www.qibiland.somee.com/api/v1/create-customer", formData)
            setModalMessage('Cảm ơn bạn! Chúng tôi sẽ liên lạc với bạn sau');
        } catch (error) {
            console.error('Error creating customer:', error);
            setModalMessage('Thông tin không hợp lệ. Vui lòng thử lại.');
        } finally {
            setModalIsOpen(true);
        }
    }
    return (
        <div className='container'>
            <div className={`popup-overlay ${showPopup ? 'show' : ''}`}>
                <button className="close-btn" onClick={closePopup}>×</button>
                <div className="popup-content">
                    <div className='contact-section'>
                        <div className='section-title'>
                            <h3 className='text-brown'>Liên hệ <span className='text-dark'>chúng tôi</span></h3>
                            <p className='text'>Đăng ký tư vấn</p>
                        </div>
                    </div>
                    <form className='contact-form mx-auto' onSubmit={handleSubmit}>
                        <div className='form-elem'>
                            <input required type="text" value={formData.name} className="form-control" placeholder='Tên' onChange={handleChange} id="name" />
                        </div>
                        <div className='form-elem'>
                            <input required type="text" value={formData.email} className="form-control" placeholder='Email' onChange={handleChange} id="email" />
                        </div>
                        <div className='form-elem'>
                            <input required type="number" value={formData.phoneNumber} className="form-control" placeholder='Số điện thoại' onChange={handleChange} id="phoneNumber" />
                        </div>
                        <button type="submit" className='bg-brown text-white submit-btn fw-3 fs-22'>Submit</button>
                    </form>
                </div>
            </div>

            <Modal
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
            >
                <Box className="custom-modal-box">
                    <Typography variant="h6" component="h2">
                        Thông Báo
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                    <Button className="custom-modal-button" onClick={() => setModalIsOpen(false)}>Đóng</Button>
                </Box>
            </Modal>
        </div>
    )
}

export default Popup