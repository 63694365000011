import React, { useState } from 'react';
import { image_mb, services, work_process } from '../../constants/data';
import "./WorkProcess.css";
import {ImCancelCircle} from "react-icons/im";
import images from '../../constants/images';

const WorkProcess = () => {
  const [imageModal, setImageModal] = useState(false);
    const [imageSource, setImageSource] = useState("");

    const setImageOnModal = (src) => {
        setImageModal(true);
        setImageSource(src);
    }
  return (
    <div className='workprocess section-p bg-white' id="apartment">
      <div className={imageModal ? "image-box show-image-box" : "image-box"}>
            <div className='image-box-content'>
                <img className='img-apartment' src = {imageSource} alt = "" />
                <span className='image-box-close-btn' onClick={() => setImageModal(false)}>
                    <ImCancelCircle size = {30} />
                </span>
            </div>
        </div>
      <div className='container'>
        <div className='workprocess-content'>
          <div className='section-title'>
            <h3 className='text-brown'>Tháp <span className='text-dark'>mika</span></h3>
          </div>
          <div>
          <img className='img_tq_2' src={images.tong_quan_2} />
          <p className='text title_mb'>Mặt bằng điển hình</p>
          </div>
          <div className='workprocess-list grid'>
            {
              image_mb.map((mb, index) => {
                return (
                  <div className='workprocess-item text-center' key={index} onClick = {() => setImageOnModal(mb.image)}>
                    <div className='workprocess-item-img'>
                      <img src={mb.image} className="mx-auto" alt="" />
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* <div className='workprocess-list grid'>
                {
                    work_process.map((workprocess, index) => {
                        return (
                            <div className='workprocess-item text-center' key = {index}>
                                <div className='workprocess-item-title flex'>
                                    <span className='text-brown fw-7'>0 {index + 1}</span>
                                    <h3 className='text-dark fw-5'>{workprocess.title}</h3>
                                </div>
                                <p className='text'>{workprocess.paragraph}</p>
                            </div>
                        )
                    })
                }
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default WorkProcess
