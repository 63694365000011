import images from "./images";

const services = [
    {
        image: `${images.services_img_1}`,
        title: "Web Design",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_2}`,
        title: "Branding",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_3}`,
        title: "UX Design",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_4}`,
        title: "Logo Create",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_5}`,
        title: "Illustration",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
    {
        image: `${images.services_img_6}`,
        title: "Graphics",
        paragraph: "Vivamus a accumsan mauris. Nunc sodales lacus nec felis suscipit dictum. Mauris auctor enim in justo euismod imperdiet."
    },
];

const works = [
    {
        image: `${images.img_location_1}`
    },
    {
        image: `${images.img_location_2}`
    },
    {
        image: `${images.img_location_3}`
    },
    {
        image: `${images.img_location_4}`
    },
    {
        image: `${images.img_location_5}`
    },
    {
        image: `${images.img_location_6}`
    },
];

const work_process = [
    {
        title: "Sketch",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "Design",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "Develop",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
    {
        title: "End-Product",
        paragraph: "Nunc sodales lacus nec felis suscipit dictum"
    },
];

const about_stats = [
    {
        image: `${images.stat_img_1}`,
        value: "1575",
        title: "Clients"
    },
    {
        image: `${images.stat_img_2}`,
        value: "2416",
        title: "Projects"
    },
    {
        image: `${images.stat_img_3}`,
        value: "4287",
        title: "Working Hours"
    },
    {
        image: `${images.stat_img_4}`,
        value: "287",
        title: "Awards"
    },
];

const testimonials = [
    {
        name: "Lelia Merritt",
        post: "Graphic Designer",
        paragraph: "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna."
    },
    {
        name: "John Huston",
        post: "Web Developer",
        paragraph: "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna."
    },
    {
        name: "Jeniffer Green",
        post: "Marketing Manager",
        paragraph: "Aliquam et odio arcu. Vestibulum pharetra tincidunt odio, sed pulvinar magna tempus quis. Mauris risus odio, semper sit amet tortor a, tristique consectetur urna."
    },
]

const img_gym = [
    {
        image: `${images.img_gym_1}`
    },
    {
        image: `${images.img_gym_2}`
    },
    {
        image: `${images.img_gym_3}`
    },
    {
        image: `${images.img_gym_4}`
    },
    {
        image: `${images.img_gym_5}`
    }
]

const img_utilities = [
    {
        image: `${images.img_pool}`,
        description: "Hồ bơi muối khoáng 800m2 "
    },
    {
        image: `${images.img_center}`,
        description: "Quảng trường ánh sáng 1000m2"
    },
    {
        image: `${images.img_club_house}`,
        description: "Club house quy mô hơn 3000m2"
    },
    {
        image: `${images.img_event}`,
        description: "Khu tổ chức sự kiện ngoài trời "
    },
    {
        image: `${images.img_park}`,
        description: "Khu vui chơi trẻ em"
    }
]

const logos = [
    {
        image: `${images.logo_img_1}`
    },
    {
        image: `${images.logo_img_2}`
    },
    {
        image: `${images.logo_img_3}`
    },
    {
        image: `${images.logo_img_4}`
    },
    {
        image: `${images.test_img_5}`
    },
    {
        image: `${images.test_img_6}`
    },
];

const image_mb = [
    {
        image: `${images.img_mb_1}`
    },
    {
        image: `${images.img_mb_2}`
    },
    {
        image: `${images.img_mb_3}`
    },
    {
        image: `${images.img_mb_4}`
    },
    {
        image: `${images.img_mb_5}`
    },
    {
        image: `${images.img_mb_6}`
    },
    {
        image: `${images.img_mb_7}`
    },
    {
        image: `${images.img_mb_8}`
    },
]

const img_pn = [
    {
        image: `${images.img_pn_1}`
    },
    {
        image: `${images.img_pn_2}`
    },
    {
        image: `${images.img_pn_6}`
    }
]

const img_pn_oth = [
    {
        image: `${images.img_pn_oth_1}`
    },
    {
        image: `${images.img_pn_oth_2}`
    },
    {
        image: `${images.img_pn_oth_3}`
    },
    {
        image: `${images.img_pn_oth_4}`
    },
    {
        image: `${images.img_pn_oth_5}`
    }
]

const img_rh = [
    {
        image: `${images.img_rh_1}`
    },
    {
        image: `${images.img_rh_2}`
    },
    {
        image: `${images.img_rh_3}`
    },
    {
        image: `${images.img_rh_5}`
    },
    {
        image: `${images.img_rh_6}`
    },
    {
        image: `${images.img_rh_7}`
    },
    {
        image: `${images.img_rh_8}`
    },
    {
        image: `${images.img_rh_9}`
    },
    {
        image: `${images.img_rh_10}`
    },

]

export { 
    services, 
    works, 
    work_process, 
    about_stats, 
    testimonials, 
    logos, 
    img_gym, 
    image_mb, 
    img_utilities, 
    img_pn, 
    img_rh, 
    img_pn_oth 
};
