import './App.css';
import "./assets/js/script";
import Header from './components/Header/Header';
import Services from './components/Services/Services';
import Work from './components/Work/Work';
import WorkProcess from './components/WorkProcess/WorkProcess';
import About from './components/About/About';
import Testimonials from './components/Testimonials/Testimonials';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Aboutus from './components/AboutUs/Aboutus';
import Sidebar from './component/Sidebar.jsx'
import MainDash from './component/MainDash/MainDash.jsx';

function App() {
  return (
    <div className="App">
      <Header />
      <Aboutus />
      {/* <About /> */}
      <Work />
      <Services />
      <WorkProcess />
      {/* <Testimonials /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
