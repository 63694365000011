import React, { useState, useEffect } from 'react';
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
    const [navToggler, setNavToggler] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const closeNavbar = () => setNavToggler(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setShowNavbar(true);
        } else {
            setShowNavbar(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${showNavbar ? 'navbar-visible' : ''}`}>
            <div className='container'>
                <div className='navbar-content'>
                    <div className='brand-and-toggler flex flex-sb'>
                        <Link to="/" className="navbar-brand text-uppercase fw-7 text-white ls-2 fs-22 w-25">
                            Khai Hoan Prime
                        </Link>
                        <button type="button" className='navbar-open-btn text-white' onClick={() => setNavToggler(!navToggler)}>
                            <FaBars size={30} />
                        </button>

                        <div className={navToggler ? "navbar-collapse show-navbar-collapse" : "navbar-collapse"}>
                            <button type="button" className='navbar-close-btn text-white' onClick={closeNavbar}>
                                <FaTimes size={30} />
                            </button>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <a href="#home" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>Trang chủ</a>
                                </li><li className='nav-item'>
                                    <a href="#aboutus" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>About us</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="#location" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>Vị Trí</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="#utilities" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>Tiện ích</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="#apartment" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>Căn hộ</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="#contact" className='nav-link text-white ls-1 text-uppercase fw-6 fs-22'>Liên hệ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar