import React from 'react';
import "./Header.css";
import Navbar from '../Navbar/Navbar';
import "./Header.css";
import { FaPlay } from 'react-icons/fa';
import { useRef, useState } from 'react';
import video_1 from "../../assets/videos/video_1.mp4";
import images from "../../assets/images/tong_quan_6.png"
const Header = () => {
  const vidRef = useRef(null);
  const [toggleVideo, setToggleVideo] = useState(false);
  const playVideo = () => {
    setToggleVideo(!toggleVideo);
    if (toggleVideo) vidRef.current.play();
    else vidRef.current.pause();
  }
  return (
    <div className='header flex flex-col' id="home">
      <Navbar />
      {/* <video className='about-video' autoPlay loop muted ref={vidRef}>
        <source src={video_1} type="video/mp4" />
      </video>
      <button type="button" className='vidPlayBtn flex flex-c' onClick={playVideo}>
        <FaPlay className='text-brown' size={28} />
      </button> */}
      
        {/* <img className='images-header' src={images}/> */}
    </div>
  )
}

export default Header