import React, { useEffect, useState } from 'react';
import "./Services.css";
import images from '../../constants/images';
import Slider from 'react-slick';
import { img_gym, img_pn, img_pn_oth, img_rh, img_utilities } from '../../constants/data';
import Popup from '../popup/popup';
import { Button } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
const Services = () => {
    const [isDelayActive, setIsDelayActive] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [activeTab, setActiveTab] = useState(1)

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    useEffect(() => {
        const handleScroll = () => {
            const ele = document.getElementById('utilities');
            if (ele) {
                const rect = ele.getBoundingClientRect();
                const isVisible = rect.top <= window.innerHeight && rect.bottom >= 100;
                if (isVisible && !isShowPopup && !isDelayActive) {
                    setIsShowPopup(true);
                    document.body.classList.add('no-scroll');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isShowPopup, isDelayActive]);

    useEffect(() => {
        if (!isShowPopup) {
            document.body.classList.remove('no-scroll');
        }
    }, [isShowPopup]);

    const closePopup = () => {
        setIsShowPopup(false);
        document.body.classList.remove('no-scroll');

        setIsDelayActive(true);
        setTimeout(() => {
            setIsDelayActive(false);
            const ele = document.getElementById('utilities');
            if (ele) {
                const rect = ele.getBoundingClientRect();
                const isVisible = rect.top <= window.innerHeight && rect.bottom >= 100;
                if (isVisible) {
                    setIsShowPopup(true);
                    document.body.classList.add('no-scroll');
                }
            }
        }, 20000); // Delay 15 giây
    };

    const handleTabClick = (tabNum) => {
        setActiveTab(tabNum)
    }

    return (
        <div className='services section-p bg-grey' id="utilities">
            {<Popup showPopup={isShowPopup} closePopup={closePopup} />}
            <div className='container'>
                <div className='services-content'>
                    <div className='section-title'>
                        <h3 className='text-brown'>Tiện <span className='text-dark'>ích</span></h3>
                    </div>
                    <div className='section-title'>
                        <img src={images.tong_quan_1} alt='tong_quan_1' />
                    </div>
                    {/* Tab SlideShow */}
                    <div className='section-title'>
                        <div>
                            <Button
                                class={activeTab === 1 ? "text-brown-btn custom-button" : "text-dark-btn custom-button"}
                                onClick={() => handleTabClick(1)}>
                                Sảnh đón khách
                            </Button>

                            <Button
                                class={activeTab === 2 ? "text-brown-btn custom-button" : "text-dark-btn custom-button"}
                                onClick={() => handleTabClick(2)}>
                                Nhà mẫu 1PN+
                            </Button>
                        </div>
                        <div className="tab-content">
                            <SwitchTransition>
                                <CSSTransition
                                    key={activeTab}
                                    timeout={500}
                                    classNames="fade"
                                >
                                    <div>
                                        {activeTab === 1 && <Slider {...settings}>
                                            {
                                                img_rh.map((rh, index) => {
                                                    return (

                                                        <img key={index} src={rh.image} alt="" className='about-img-slide-1 mx-auto' />
                                                    )
                                                })
                                            }
                                        </Slider>}
                                        {activeTab === 2 && <Slider {...settings}>
                                            {
                                                img_pn_oth.map((pn_oth, index) => {
                                                    return (

                                                        <img key={index} src={pn_oth.image} alt="" className='about-img-slide-2 mx-auto' />
                                                    )
                                                })
                                            }
                                        </Slider>}
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>
                        </div>
                    </div>
                    {/* Gym Center */}
                    <div className='services-list-2-re grid section-title'>
                        <Slider {...settings}>
                            {
                                img_gym.map((gym, index) => {
                                    return (

                                        <img key={index} src={gym.image} alt="" className='about-img-slide mx-auto' />
                                    )
                                })
                            }
                        </Slider>

                        <div className='section-title'>
                            <h3 className='text-brown'>Gym Center <br /><span className='text-dark'>Khải Hoàn Prime </span></h3>
                            <p className='text mx-auto'>Nâng tầm cuộc sống</p>
                        </div>
                    </div>

                    <div className='slide-show section-title'>
                        <Slider {...settings}>
                            {
                                img_utilities.map((utilities, index) => {
                                    return (
                                        <div key={index} className='slide-item'>
                                            <img src={utilities.image} alt="" className='about-img-slide-3 mx-auto' />
                                            <div className='slide-description'>
                                                <h3 className='text-brown'>
                                                    {utilities.description}
                                                    <br /><span className='text-dark'>Khải Hoàn Prime</span>
                                                </h3>
                                                <p className='text mx-auto'>Nâng tầm cuộc sống</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services