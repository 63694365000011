import React, { useRef, useState } from 'react';
import "./Aboutus.css";
import aboutus from "../../assets/videos/utilities.mp4";
import { FaPlay } from 'react-icons/fa';
const Aboutus = () => {
  const vidRef = useRef(null);
    const [toggleVideo, setToggleVideo] = useState(false);
    const playVideo = () => {
        setToggleVideo(!toggleVideo);
        if (toggleVideo) vidRef.current.play();
        else vidRef.current.pause();
    }
  return (
    <div className='services section-p bg-grey' id="aboutus">
      <div className='container'>
        <div className='services-content'>
          <div className='section-title'>
            <h3 className='text-brown'>About <span className='text-dark'>us</span></h3>

          </div>
          <div className='section-title'>
            <p className='text'>Định hình phong cách sống chuẩn resort tại Nam Sài Gòn, Khải Hoàn Prime sở hữu mật độ xây dựng tương tự các resort cao cấp, thừa hưởng cảnh quan thiên nhiên xanh mát, thoáng đãng mang đến cho cư dân trải nghiệm sống thời thượng và an lành. Đồng thời, dự án còn liền kề khu đô thị Phú Mỹ Hưng, dễ dàng tiếp cận hệ thống tiện ích đa dạng và đẳng cấp hàng đầu khu vực.</p>
            <div className='container-video custom-video'>
            <video className='about-video' autoPlay loop ref={vidRef}>
              <source src={aboutus} type="video/mp4" />
            </video>
            <button type="button" className='vidPlayBtnn flex flex-c' onClick={playVideo}>
              <FaPlay className='text-brown' size={28} />
            </button>
            </div>
            <table border="1" cellspacing="0" cellpadding="10">
              <thead>
                <tr>
                  <th colspan="2">Phát Triển Dự Án</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Tập đoàn</strong></td>
                  <td>Khải Hoàn Land</td>
                </tr>
                <tr>
                  <td><strong>Vị trí</strong></td>
                  <td>Lê Văn Lương, Nhơn Đức, Nhà Bè, TP.HCM</td>
                </tr>
                <tr>
                  <td><strong>Tổng Diện Tích</strong></td>
                  <td>19.730 m²</td>
                </tr>
                <tr>
                  <td><strong>Mật Độ Xây Dựng</strong></td>
                  <td>26% (3 tháp căn hộ 21%, Clubhouse 5%)</td>
                </tr>
                <tr>
                  <td><strong>Quy Mô</strong></td>
                  <td>
                    3 Toà Tháp:
                    <ul>
                      <li><strong>Tháp Vinci</strong>: 27 tầng – 569 căn hộ</li>
                      <li><strong>Tháp Mika</strong>: 25 tầng – 360 căn hộ</li>
                      <li><strong>Tháp Gali</strong>: 27 tầng – 328 căn hộ</li>
                    </ul>
                    2 Tầng thương mại<br />
                    2 Tầng hầm
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutus