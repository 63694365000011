import pattern_00 from "../assets/images/pattern_00.png";
import pattern_1 from "../assets/images/pattern_1.png";
import services_img_1 from "../assets/images/services_img_1.svg";
import services_img_2 from "../assets/images/services_img_2.svg";
import services_img_3 from "../assets/images/services_img_3.svg";
import services_img_4 from "../assets/images/services_img_4.svg";
import services_img_5 from "../assets/images/services_img_5.svg";
import services_img_6 from "../assets/images/services_img_6.svg";
import stat_img_1 from "../assets/images/stat_img_1.png";
import stat_img_2 from "../assets/images/stat_img_2.png";
import stat_img_3 from "../assets/images/stat_img_3.png";
import stat_img_4 from "../assets/images/stat_img_4.png";
import logo_img_1 from "../assets/images/logo_img_1.png";
import logo_img_2 from "../assets/images/logo_img_2.png";
import logo_img_3 from "../assets/images/logo_img_3.png";
import logo_img_4 from "../assets/images/logo_img_4.png";
import logo_img_5 from "../assets/images/logo_img_5.png";
import logo_img_6 from "../assets/images/logo_img_6.png";
import img_location_1 from "../assets/images/img_location_1.png";
import img_location_2 from "../assets/images/img_location_2.png";
import img_location_3 from "../assets/images/img_location_3.png";
import img_location_4 from "../assets/images/img_location_4.png";
import img_location_5 from "../assets/images/img_location_5.png";
import img_location_6 from "../assets/images/img_location_6.png";
import tong_quan_1 from "../assets/images/tong_quan_1.png";
import tong_quan_2 from "../assets/images/tong_quan_2.png";
import tong_quan_3 from "../assets/images/tong_quan_3.png";
import subscribe_background from "../assets/images/subscribe_background.jpeg";
import img_gym_1 from "../assets/images/img_gym_1.png";
import img_gym_2 from "../assets/images/img_gym_2.png";
import img_gym_3 from "../assets/images/img_gym_3.png";
import img_gym_4 from "../assets/images/img_gym_4.png";
import img_gym_5 from "../assets/images/img_gym_5.png";
import img_mb_1 from "../assets/images/img_mb_1.png";
import img_mb_2 from "../assets/images/img_mb_2.png";
import img_mb_3 from "../assets/images/img_mb_3.png";
import img_mb_4 from "../assets/images/img_mb_4.png";
import img_mb_5 from "../assets/images/img_mb_5.png";
import img_mb_6 from "../assets/images/img_mb_6.png";
import img_mb_7 from "../assets/images/img_mb_7.png";
import img_mb_8 from "../assets/images/img_mb_8.png";
import img_center from "../assets/images/img_utilities/img_center.png";
import img_club_house from "../assets/images/img_utilities/img_club_house.png";
import img_event from "../assets/images/img_utilities/img_event.png";
import img_park from "../assets/images/img_utilities/img_park.png";
import img_pool from "../assets/images/img_utilities/img_pool.png";
import img_pn_1 from "../assets/images/img_utilities/img_1_PN+/img_pn_1.png"
import img_pn_2 from "../assets/images/img_utilities/img_1_PN+/img_pn_2.png"
import img_pn_6 from "../assets/images/img_utilities/img_1_PN+/img_pn_6.png"
import img_pn_oth_1 from "../assets/images/img_utilities/img_1_PN+_other/img_pn_oth_1.png"
import img_pn_oth_2 from "../assets/images/img_utilities/img_1_PN+_other/img_pn_oth_2.png"
import img_pn_oth_3 from "../assets/images/img_utilities/img_1_PN+_other/img_pn_oth_3.png"
import img_pn_oth_4 from "../assets/images/img_utilities/img_1_PN+_other/img_pn_oth_4.png"
import img_pn_oth_5 from "../assets/images/img_utilities/img_1_PN+_other/img_pn_oth_5.png"

import img_rh_1 from "../assets/images/img_utilities/reception_hall/img_rh_1.png"
import img_rh_2 from "../assets/images/img_utilities/reception_hall/img_rh_2.png"
import img_rh_3 from "../assets/images/img_utilities/reception_hall/img_rh_3.png"
import img_rh_4 from "../assets/images/img_utilities/reception_hall/img_rh_4.png"
import img_rh_5 from "../assets/images/img_utilities/reception_hall/img_rh_5.png"
import img_rh_6 from "../assets/images/img_utilities/reception_hall/img_rh_6.png"
import img_rh_7 from "../assets/images/img_utilities/reception_hall/img_rh_7.png"
import img_rh_8 from "../assets/images/img_utilities/reception_hall/img_rh_8.png"
import img_rh_9 from "../assets/images/img_utilities/reception_hall/img_rh_9.png"
import img_rh_10 from "../assets/images/img_utilities/reception_hall/img_rh_10.png"

const images = {
    pattern_00, 
    pattern_1, 
    services_img_1, services_img_2, services_img_3, services_img_4, services_img_5, services_img_6, 
    stat_img_1, stat_img_2, stat_img_3, stat_img_4, 
    logo_img_1, logo_img_2, logo_img_3, logo_img_4, logo_img_5, logo_img_6, 
    img_location_1, img_location_2, img_location_3, img_location_4, img_location_5, img_location_6, 
    tong_quan_1, tong_quan_2,tong_quan_3,
    img_gym_1, img_gym_2, img_gym_3, img_gym_4, img_gym_5,
    img_mb_1, img_mb_2,img_mb_3,img_mb_4,img_mb_5,img_mb_6,img_mb_7,img_mb_8,
    img_center, img_club_house, img_event, img_park, img_pool,
    subscribe_background,
    img_pn_1,img_pn_2,img_pn_6,
    img_pn_oth_1, img_pn_oth_2, img_pn_oth_3, img_pn_oth_4, img_pn_oth_5,
    img_rh_1,img_rh_2,img_rh_3,img_rh_4,img_rh_5,img_rh_6,img_rh_7,img_rh_8,img_rh_9,img_rh_10,
}
export default images;