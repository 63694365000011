import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import "./Table.css";
import { useState, useEffect } from "react";


const makeStyle = (status) => {
  return {
    color: "white",
    backgroundColor: status ? "green" : "red",
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
  }
};

export default function BasicTable() {
  const [customers, setCustomers] = useState([])
  useEffect(() => {
    fetchCustomerList();
    const interval = setInterval(fetchCustomerList, 5000);
    return () => clearInterval(interval);
  }, [])
  
  const fetchCustomerList = async () => {
    try{
      const response = await axios.get("https://www.qibiland.somee.com/api/v1/get-all");
    setCustomers(response.data)  
    }catch (error){
      console.error("Error fetching customers:", error);
    }
  }
  const handleStatus = async (customerId, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      const response = await axios.put(`https://www.qibiland.somee.com/api/v1/update-customer-sts/${customerId}`, { status: newStatus });
      if (response.status === 200) {
        fetchCustomerList();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="TableContainerWrapper">
      <TableContainer
        component={Paper}
        style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
        className="TableContainer"
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="row-title">
              <TableCell className="title" align="left">
                Name
              </TableCell>
              <TableCell className="title" align="left">
                Email
              </TableCell>
              <TableCell className="title" align="left">
                Phone Number
              </TableCell>
              <TableCell className="title" align="left">
                Date
              </TableCell>
              <TableCell className="title" align="left">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: "white" }}>
            {customers?.map((customer, index) => (
              <TableRow
                key={customer.customerId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="content-table" component="th" scope="row">
                  {customer?.name}
                </TableCell>
                <TableCell className="content-table" align="left">
                  {customer?.email}
                </TableCell>
                <TableCell className="content-table" align="left">
                  {customer?.phoneNumber}
                </TableCell>
                <TableCell className="content-table" align="left">
                  {customer?.date}
                </TableCell>
                <TableCell className="content-table" align="left">
                  <button
                    className="status"
                    style={makeStyle(customer.status)}
                    onClick={() => handleStatus(customer?.customerId, customer.status)}
                  >
                    {customer?.status ? "Đã liên hệ" : "Chưa liên hệ"}
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
