import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import Login from './components/Login/Login';
import MainDash from '../src/component/MainDash/MainDash.jsx'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path = "/" index element = {<App />}></Route>
      <Route path = "adminmadaothanhcong/dashboard" element={<MainDash />} />
      {/* <Route path = "authencation/login" element = {<Login />}></Route> */}
    </Routes>
  </BrowserRouter>
);

