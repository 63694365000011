import React from 'react';
import "./Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterest, FaBehance } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className='footer bg-dark section-p' id="footer">
            <div className='container'>
                <div className='section-title'>
                    <h3 className='text-brown'>Khai Hoan <span className='text-white'>Prime</span></h3>
                </div>
                <div className='footer-content'>
                    <ul className='footer-social-links flex flex-c'>
                        <li>
                            <a href="/" className='text-white'>
                                <FaFacebookF />
                            </a>
                        </li>
                        {/* <li>
                            <a href="/" className='text-white'>
                                <FaTwitter />
                            </a>
                        </li>
                        <li>
                            <a href="/" className='text-white'>
                                <FaInstagram />
                            </a>
                        </li> */}
                    </ul>
                    <div className='footer-contact'>
                    <div>
                        <p className='text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text'>Thông tin liên hệ</p>
                            <p className='text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text'>Gmail: quocbao181202@gmail.com</p>
                            <p className='text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text'>Số điện thoại: 0765458750</p>
                        </div>
                        <div>
                            <br/>
                            <br/>
                        <p className='text-center fs-14 text-white op-07 fw-3 ls-1 reserved-text'>&copy; Bản quyền: Khai Hoan Prime</p>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer