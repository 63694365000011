import React from "react";
import Table from "../Table/Table";
import "./MainDash.css";
import Sidebar from "../Sidebar";
const MainDash = () => {
  return (
    <>
    <div className="Dashboard">
      <div className="AppGlass">
      <Sidebar />
        <div className="MainDash">
          <h1>Dashboard</h1>
          <Table />
        </div>
      </div>
    </div></>
  );
};

export default MainDash;
